import CInputDateRange from '@/components/CInputDateRange'
import CInputDate from '@/components/CInputDate'
import UpdateTasks from '@/components/UpdateTasks'
import { mapActions } from 'vuex'
import table from '@/mixin/table'
import GCFilter from '@/components/GCFilter/index'
import modalComponent from '@/mixin/modalComponent'
import Gate from '@/services/Gate/Gate'
import { notifyByPlan } from '@/services/Notify/Toasts'
import NoTasks from '@/components/SmartTable/partials/NoTasks.vue'

export default {
  components: {
    NoTasks,
    CInputDateRange,
    CInputDate,
    UpdateTasks,
    GCFilter,
  },
  inject: ['toast', 'mountedComponent'],
  mixins: [table, modalComponent],
  data() {
    return {
      exceptColumn: ['company_name', 'type', 'required_action'],
      tasks: [],
      fields: [
        {
          key: 'task_name_with_count',
          label: 'Task Name',
          _style: '',
        },
        { key: 'status', label: 'Status', _style: '' },
        {
          key: 'task_id',
          label: 'Task ID',
          _style: '',
        },
        {
          key: 'project_name',
          label: 'Project',
          _style: '',
          link: { name: 'Project', params: { id: 'project_id' } },
        },
        { key: 'due_date', label: 'Due Date', _style: '' },
        { key: 'type', label: 'Type', _style: '' },
        { key: 'priority', label: 'Priority', _style: '' },
        {
          key: 'internal_assignee',
          label: 'Internal Assignee',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: { user_id: 'internal_assignee_id', id: 'company_id' },
          },
        },
        {
          key: 'external_assignee',
          label: 'External Assignee',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: {
              user_id: 'external_assignee_id',
              id: 'external_company_id',
            },
          },
        },
        { key: 'scope_name', label: 'Scope Name', _style: '' },
        { key: 'package', label: 'Assigned Contract Folder', _style: '' },
      ],
      status: [],
      selectedTasks: [],
      editTasks: false,
      editData: {
        due_date: null,
        status: null,
        priority: null,
        internal_assignee: null,
        reminders: null,
      },
      tempFilter: {},
    }
  },
  watch: {
    status() {
      this.fetchData()
    },
  },
  methods: {
    ...mapActions(['fetchTasks']),
    goToView(item) {
      if (item.type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: item.project_id, task_id: item.id },
        })
      } else if (item.type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: item.project_id, task_id: item.id },
        })
      } else if (item.type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: item.project_id, task_id: item.id },
        })
      }
    },
    makeParams(f = {}) {
      f = Object.assign(this.tempFilter, f)
      return {
        params: {
          ...f,
          per_page: this.perPage,
          page: this.activePage,
          sort_field: this.sort && this.sort.column,
          sort_direction: this.sort && this.sort.state,
          company_id: this.$route.params.id,
        },
      }
    },
    fetchData(filter = {}) {
      this.loadingTable = true
      this.tasks = []
      this.fetchTasks(this.makeParams(filter))
        .then((res) => {
          this.key++
          this.selectedTasks = []
          this.tasks = res.data
          this.pages = res.meta.last_page
          this.loadingTable = false
        })
        .catch(() => {
          this.loadingTable = false
        })
    },
    setCheck(value) {
      this.selectedTasks = value.map((i) => i.id)
    },
    closeEdit() {
      this.editTasks = false
      this.fetchData()
    },
    async handleEditTask() {
      const check = await Gate.can('bulkAction', 'task')
      if (!check) {
        notifyByPlan()
        return false
      }
      this.editTasks = check
    },
    async eventRevokeTasks() {
      const check = await Gate.can('bulkAction', 'task')
      if (!check) {
        notifyByPlan()
        return false
      }
      this.$modal
        .open({
          close: 'Close',
          success: 'Revoke',
          label: 'Do you want to revoke selected tasks?',
          size: 'lg',
        })
        .then(() => {
          this.eventRevokeTasks()
          this.$http.tasks
            .editTasksMass({
              task_id: this.selectedTasks,
              status: 'Revoked',
            })
            .then(() => {
              this.toast('success', 'The task has been successfully revoked')
              this.$modal.close()
              this.selectedTasks = []
              this.fetchData()
            })
            .catch(({ response }) => {
              if (response.data.errors?.status?.length) {
                this.toast('error', response.data.errors.status[0])
                return
              }
              this.errors = response.data.errors
                ? this.getErrors(response.data.errors)
                : {}

              response.status === 403
                ? this.toast(
                    'error',
                    'It is impossible to revoke tasks assigned to your company',
                  )
                : this.toast('error', response.data.message)
            })
            .finally(() => this.$modal.close())
        })
    },
  },
}
